import React, { FC } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from '@mui/material';
import {} from 'organisms/InvoicesTable/helpers';
import {
  ServicesWrapper,
  StyledServiceTableText,
  StyledServiceTableTitle,
} from 'organisms/SalesReportTable/Service/Service.styles';
import { StyledDetailsContainer } from 'organisms/CloudOpsReportAdmin/CloudOpsReportAdmin.styles';
import Link from 'atoms/Link/Link';
import { StyledDetailsText } from 'molecules/Table/styles/Text.styles';

import { roundUp } from 'utils/helpers/formValidators';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { SalesReportRow } from 'organisms/SalesReportTable';

const Service: FC<{ row: SalesReportRow }> = ({ row }) => (
  <ServicesWrapper>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <StyledServiceTableTitle>Services</StyledServiceTableTitle>
          </TableCell>
          <TableCell sx={{ maxWidth: '620px' }}>
            <StyledServiceTableTitle>Description</StyledServiceTableTitle>
          </TableCell>
          <TableCell>
            <StyledServiceTableTitle $right>Amount</StyledServiceTableTitle>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {row.services.map((item) => (
          <TableRow key={`service-${item.amount}`}>
            <TableCell>
              <StyledServiceTableText>{item.name}</StyledServiceTableText>
            </TableCell>
            <TableCell>
              <StyledServiceTableText>
                {item.description?.substring(0, 4000)}
                {item.description?.length > 4000 ? '...' : ''}
              </StyledServiceTableText>
            </TableCell>
            <TableCell>
              <StyledServiceTableText $right>
                {item.amount
                  ? currencyFormatter(
                      Number(roundUp(item.amount)),
                      row.currency
                    )
                  : '$0.00'}
              </StyledServiceTableText>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell sx={{ p: '0 !important' }} colSpan={100}>
            <Grid container spacing={0} sx={{ p: '8px 24px' }}>
              <Grid item xs>
                <StyledDetailsContainer $firstCol>
                  {row.link && (
                    <Link
                      to={row.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View ‘Deal’ details in Hubspot
                    </Link>
                  )}
                </StyledDetailsContainer>
              </Grid>
              <Grid item xs="auto">
                <StyledDetailsContainer>
                  <StyledDetailsText>Subtotal</StyledDetailsText>
                  <StyledDetailsText>Zazmic discount</StyledDetailsText>
                  <StyledDetailsText>Tax</StyledDetailsText>
                  {row.invoice_status === 'paid' &&
                    Number(row.transaction_fee) > 0 && (
                      <StyledDetailsText>
                        Payment Processing Fee
                      </StyledDetailsText>
                    )}
                  <StyledDetailsText $dividerTop $bold>
                    Total
                  </StyledDetailsText>
                  {Number(row.payments_credits) > 0 &&
                    Number(row.payments_credits) <
                      Number(row.invoice_amount) && (
                      <StyledDetailsText>Payments/Credits</StyledDetailsText>
                    )}
                  <StyledDetailsText $bold $textTransform="none">
                    Amount due
                  </StyledDetailsText>
                </StyledDetailsContainer>
              </Grid>
              <Grid item xs="auto" minWidth={120}>
                <StyledDetailsContainer>
                  <StyledDetailsText>
                    {currencyFormatter(Number(row.subtotal), row.currency)}
                  </StyledDetailsText>
                  <StyledDetailsText>
                    {currencyFormatter(
                      Number(row.discount_amount),
                      row.currency
                    )}
                  </StyledDetailsText>
                  <StyledDetailsText>
                    {currencyFormatter(
                      Number(row.total_tax_amount),
                      row.currency
                    )}
                  </StyledDetailsText>
                  {row.invoice_status === 'paid' &&
                    Number(row.transaction_fee) > 0 && (
                      <StyledDetailsText>
                        {currencyFormatter(
                          Number(row.transaction_fee),
                          row.currency
                        )}
                      </StyledDetailsText>
                    )}
                  <StyledDetailsText $dividerTop $bold>
                    {currencyFormatter(Number(row.total), row.currency)}
                  </StyledDetailsText>
                  {Number(row.payments_credits) > 0 &&
                    Number(row.payments_credits) <
                      Number(row.invoice_amount) && (
                      <StyledDetailsText>
                        {currencyFormatter(
                          Number(row.payments_credits),
                          row.currency
                        )}
                      </StyledDetailsText>
                    )}
                  <StyledDetailsText $bold>
                    {currencyFormatter(Number(row.amount_due), row.currency)}
                  </StyledDetailsText>
                </StyledDetailsContainer>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </ServicesWrapper>
);

export default Service;
