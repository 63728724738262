import React, { ReactNode, useRef, useState } from 'react';

import { IconButton, Tooltip, FormControl, Box } from '@mui/material';
import {
  StyledSelect,
  StyledLabel,
  StyledMenuItem,
  StyledSelectValue,
} from './StatusSelect.styles';
import { IconTextFieldWrapper } from 'molecules/IconTextField/IconTextField.styles';
import { palette } from 'utils/styles/variables';

import { ReactComponent as RoleFilterIcon } from 'assets/icons/role-filter.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ADMIN, SALES_REP, SUPER_ADMIN } from 'utils/constants/roles';

interface dataFormProps {
  onChange?: (arg0?: string) => void;
}

const roleOptions = [
  {
    label: 'Super Admin',
    value: SUPER_ADMIN,
  },
  {
    label: 'Admin',
    value: ADMIN,
  },
  {
    label: 'Sales Rep',
    value: SALES_REP,
  },
];

const getUserRoleLabel = (userRole: string) => {
  if (userRole === SUPER_ADMIN) return 'Super Admin';
  if (userRole === ADMIN) return 'Admin';
  if (userRole === SALES_REP) return 'Sales Rep';
  return '';
};

const RoleSelect = ({ onChange }: dataFormProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');

  const boxRef = useRef(null);

  const handleItemClick = (option: string) => {
    setSelected(option);
    if (onChange) {
      onChange(option);
    }
  };

  const handleClickIcon = () => {
    setTimeout(() => {
      setOpen((isOpen) => !isOpen);
    }, 100);
  };

  const handleClick = () => {
    setOpen((isOpen) => (isOpen ? false : !selected));
    setSelected('');

    if (onChange) onChange(undefined);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <IconTextFieldWrapper>
        <Tooltip title="Filter by role">
          <IconButton
            aria-label="close"
            onMouseDown={handleClickIcon}
            sx={{
              p: '0 !important',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            disabled={open}
          >
            <RoleFilterIcon width={24} height={24} />
          </IconButton>
        </Tooltip>

        <FormControl
          variant="standard"
          sx={{
            minWidth: 180,
            display: open || selected ? 'flex' : 'none',
          }}
          size="small"
        >
          <StyledLabel
            id="filter-label"
            shrink={false}
            sx={{ transform: 'translate(35%, 17px) !important' }}
          >
            {selected ? '' : 'Select role'}
          </StyledLabel>
          <StyledSelect
            open={open}
            value={selected}
            id="filter-label"
            labelId="filter-label"
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            MenuProps={{
              container: boxRef.current,
              className: 'currency-status-select',
              hideBackdrop: true,
              onBlur: () => {
                setTimeout(() => setOpen(false), 150);
              },
              sx: {
                position: 'unset',
                '.MuiPaper-root': {
                  minWidth: '180px !important',
                  width: 180,
                  '.MuiList-root': {
                    padding: '4px 0 !important',
                  },
                },
              },
            }}
            IconComponent={() =>
              selected ? (
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    handleClick();
                  }}
                >
                  <CloseIcon
                    color={palette.error.main}
                    width={16}
                    height={16}
                  />
                </IconButton>
              ) : null
            }
            renderValue={(selectValue: unknown): ReactNode => (
              <StyledSelectValue>
                {getUserRoleLabel(selectValue as string)}
              </StyledSelectValue>
            )}
          >
            {roleOptions?.map(({ label, value }) => (
              <StyledMenuItem
                key={value}
                value={value}
                onClick={() => handleItemClick(value)}
              >
                {label}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </IconTextFieldWrapper>
      {/* Container for menu paper */}
      <Box
        sx={{
          display: open ? 'block' : 'none',
          width: '200px',
          height: '300px',
          position: 'absolute',
          top: '26px',
          right: '-10px',
        }}
      >
        <Box
          ref={boxRef}
          sx={{
            zIndex: 10,
            position: 'relative',
            width: '100%',
            height: '100%',
            '.MuiPaper-root': {
              top: '0 !important',
              left: `10px !important`,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default RoleSelect;
