import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  ChangeEvent,
} from 'react';
import { camelCase, mapKeys, snakeCase } from 'lodash-es';
import axios from 'axios';
import { MenuItem, Stack, TextField } from '@mui/material';
import {
  getRoleLabel,
  ADMIN,
  SUPER_ADMIN,
  roles,
  SALES_REP,
} from 'utils/constants/roles';
import { useBaoSelector } from 'utils/hooks/redux';
import { CaptchaModal } from 'molecules/CaptchaModal/CaptchaModal';
import Table from 'molecules/Table';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import { UserState } from 'store/user/userSlice';
import {
  StyledCellContainer,
  StyledTableContainer,
} from 'molecules/Table/styles';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import { Order } from 'organisms/InvoicesTable/types';
import Modal from 'molecules/Modal';
import {
  ModalContent,
  ModalActions,
  ModalCloseButton,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { ModalContentText } from 'molecules/Modal/ModalText.styles';
import { ReactComponent as SettingsIcon } from 'assets/icons/edit-settings.svg';
import {
  StyledRow,
  StyledRowText,
  StyledTextButton,
} from 'organisms/ProjectDialogs/CloudOpsProject.styles';
import dayjs from 'dayjs';
import { palette } from 'utils/styles/variables';

type RolesTableProps = {
  addingUser: boolean;
  search?: string;
  role?: string;
};

const initial = { page: 1, limit: 20, total: 0 };
type sortByType = 'user_type' | 'name' | 'email' | 'created_at';

const RolesTable = ({ addingUser, search, role }: RolesTableProps) => {
  const account = useBaoSelector((state) => state.user.value);
  const isSuperAdmin = account.userType === SUPER_ADMIN;

  const [users, setUsers] = useState<
    {
      id: string;
      name: string;
      surname: string;
      email: string;
      userType: string;
    }[]
  >([]);

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<sortByType>('created_at');
  const [pagination, setPagination] = useState(initial);

  const [selectedUser, setSelectedUser] = useState<
    Partial<UserState> | undefined
  >();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);
  const [showRoleDialog, setShowRoleDialog] = useState<boolean>(false);
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);

  const [selectedRole, setSelectedRole] = useState<{
    value: string;
    error: string;
  }>({ value: '', error: '' });

  const handleClickDetails = (row: Partial<UserState>) => {
    setSelectedUser(row);
    setShowDetailsDialog(true);
  };

  const columns = useMemo(
    () => [
      {
        key: 'name',
        label: 'Full Name',
        sortable: true,
        width: '20%',
        render: ({ name, surname }: UserState) => (
          <StyledCellText>
            {name} {surname}
          </StyledCellText>
        ),
      },
      {
        key: 'email',
        label: 'Email',
        sortable: true,
        width: '35%',
        render: ({ email }: UserState) => (
          <StyledCellText>{email}</StyledCellText>
        ),
      },
      {
        key: 'userType',
        label: 'Role',
        sortable: true,
        width: '17%',
        render: ({ userType }: UserState) => (
          <StyledCellText>
            {getRoleLabel(userType as 'admin' | 'superadmin')}
          </StyledCellText>
        ),
      },
      {
        key: 'createdAt',
        label: 'Date Created',
        greyText: 'Create By',
        sortable: true,
        width: '20%',
        render: ({ createdAt, createdBy }: UserState) => (
          <StyledCellContainer $vStack $pr="40px">
            <StyledCellText>
              {createdAt ? dayjs(createdAt).format('MMM DD, YYYY') : ' '}
            </StyledCellText>
            <StyledCellText $textType="grey">{createdBy || ''}</StyledCellText>
          </StyledCellContainer>
        ),
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        width: '8%',
        render: (user: UserState) => (
          <StyledCellContainer $right $hStack>
            <SettingsIcon
              aria-label="user-details"
              onClick={() => handleClickDetails(user)}
              style={{
                color: '#6F9FE9',
                position: 'relative',
                cursor: 'pointer',
              }}
            />
          </StyledCellContainer>
        ),
      },
    ],
    [account]
  );

  const handleDelete = useCallback(async (user: Partial<UserState>) => {
    // todo add try/catch
    await axios.delete(
      `${process.env.REACT_APP_USER_SERVICE}/delete/${user.id}`
    );
    await fetchPageData(initial);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchPageData({ ...initial, limit: pagination.limit });
    })();
  }, [addingUser, search, role, order, orderBy]);

  const deleteMethod = useCallback(async () => {
    if (!selectedUser) return;

    setShowCaptcha(false);
    await handleDelete(selectedUser);
    setSelectedUser(undefined);
  }, [handleDelete, selectedUser]);

  const showCaptchaDialog = useCallback(() => {
    setShowDeleteDialog(false);
    setShowCaptcha(true);
  }, []);

  const hideCaptcha = useCallback(() => {
    setShowCaptcha(false);
    setShowDetailsDialog(true);
  }, []);

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      const nextState = { ...pagination, page: newPage + 1 };
      setPagination(nextState);
      await fetchPageData(nextState);
    },
    [pagination]
  );

  const handleChangeRowsPerPage = useCallback(async (limit: number) => {
    const nextState = {
      ...pagination,
      limit,
      page: 1,
    };
    setPagination(nextState);
    await fetchPageData(nextState);
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: sortByType
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const isDesc = orderBy === property && order === 'desc';

    // on third click reset to default
    if (isDesc) {
      setOrder('desc');
      setOrderBy('created_at');
      return;
    }

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRole = useCallback(async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_USER_SERVICE}/update-role`, {
        user_id: selectedUser?.id,
        user_type: selectedRole.value,
      });

      setSelectedRole({ value: '', error: '' });
      setShowRoleDialog(false);
      setShowDetailsDialog(true);
      fetchPageData(pagination);
    } catch (err) {
      setSelectedRole((prev) => ({ ...prev, error: err }));
    }
  }, [pagination, selectedRole, selectedUser]);

  const handleResendInvite = useCallback(async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_USER_SERVICE}/resend/invite?user_id=${selectedUser?.id}`
      );
      fetchPageData(pagination);
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
    }
  }, [pagination, selectedUser]);

  async function fetchPageData(filter: { [k: string]: string | number }) {
    const { total, ...params } = filter;
    if (orderBy) {
      params.sort = snakeCase(orderBy); // `sort[${snakeCase(orderBy)}]=${order}`;
      params.sort_order = order;
    }
    await axios
      .get(`${process.env.REACT_APP_USER_SERVICE}/users`, {
        params: { ...params, search, user_types: role },
      })
      .then(({ data }) => {
        const { users: usersData, count } = data;
        const newUsers = usersData.map((user: (typeof users)[0]) =>
          mapKeys(user, (v, k) => camelCase(k))
        );
        setUsers(newUsers);
        setPagination({ ...params, total: count || total } as typeof initial);

        const isUserSelected = newUsers.find(
          (u: UserState) => u.id === selectedUser?.id
        );
        if (isUserSelected) {
          setSelectedUser(isUserSelected);
        }
      });
  }

  const getUserRoleLabel = (userRole: string) => {
    if (userRole === SUPER_ADMIN) return 'Super Admin';
    if (userRole === ADMIN) return 'Admin';
    if (userRole === SALES_REP) return 'Sales Rep';
    return '';
  };

  const isActionsEnabled = isSuperAdmin && account.userId !== selectedUser?.id;
  const isResendInviteEnabled = () => {
    if (!isActionsEnabled) return false;
    if (!selectedUser?.lastResentInvite) return true;
    return dayjs().isAfter(dayjs(selectedUser.lastResentInvite), 'day');
  };

  return (
    <>
      <StyledTableContainer $new sx={{ position: 'relative' }}>
        <Table
          idKey="userId"
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
          data={users || []}
        />
        <StyledTablePagination
          labelDisplayedRows={() => null}
          rowsPerPageOptions={[]}
          count={pagination.total}
          rowsPerPage={pagination.limit}
          page={pagination.total ? pagination.page - 1 : 0}
          onPageChange={handleChangePage}
          ActionsComponent={(props) => (
            <PaginationActions
              {...props}
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[20, 50, 100]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        />
      </StyledTableContainer>

      <Modal
        open={showDetailsDialog}
        maxWidth="xs"
        title="User details"
        onClose={() => {
          setShowDetailsDialog(false);
          setSelectedUser(undefined);
        }}
      >
        <ModalContent sx={{ padding: '0 16px 24px !important' }}>
          <Stack gap="8px">
            <StyledRow>
              <StyledRowText>
                Name:{' '}
                <StyledRowText $bold>
                  {selectedUser?.name || ''} {selectedUser?.surname || ''}
                </StyledRowText>
              </StyledRowText>
              {isActionsEnabled && (
                <StyledTextButton
                  variant="text"
                  onClick={() => {
                    setShowDetailsDialog(false);
                    setShowDeleteDialog(true);
                  }}
                >
                  Delete user
                </StyledTextButton>
              )}
            </StyledRow>
            <StyledRow>
              <StyledRowText>
                Email:{' '}
                <StyledRowText $bold>{selectedUser?.email || ''}</StyledRowText>
              </StyledRowText>
            </StyledRow>
            <StyledRow>
              <StyledRowText>
                Role:{' '}
                <StyledRowText $bold>
                  {getUserRoleLabel(selectedUser?.userType as string) || ''}
                </StyledRowText>
              </StyledRowText>
              {isActionsEnabled && (
                <StyledTextButton
                  variant="text"
                  onClick={() => {
                    setSelectedRole({
                      value: selectedUser?.userType as string,
                      error: '',
                    });
                    setShowDetailsDialog(false);
                    setShowRoleDialog(true);
                  }}
                >
                  Change role
                </StyledTextButton>
              )}
            </StyledRow>
            {selectedUser?.lastLoggedTime ? (
              <StyledRow>
                <StyledRowText>
                  Last logged in at:{' '}
                  <StyledRowText $bold>
                    {dayjs(selectedUser.lastLoggedTime).format('MMM DD, YYYY')}
                  </StyledRowText>
                </StyledRowText>
              </StyledRow>
            ) : (
              <StyledRow>
                <StyledRowText $color={palette.warning[500]}>
                  Invitation has not accepted yet
                </StyledRowText>
                {isResendInviteEnabled() ? (
                  <StyledTextButton variant="text" onClick={handleResendInvite}>
                    Resend invitation
                  </StyledTextButton>
                ) : (
                  <StyledRowText $color={palette.warning[500]}>
                    Invitation resent
                  </StyledRowText>
                )}
              </StyledRow>
            )}
            <StyledRow>
              <StyledRowText>
                Created at:{' '}
                {selectedUser?.createdAt ? (
                  <StyledRowText $bold>
                    {dayjs(selectedUser.createdAt).format('MMM DD, YYYY')}
                  </StyledRowText>
                ) : (
                  ''
                )}
              </StyledRowText>
            </StyledRow>
            <StyledRow>
              <StyledRowText>
                Created by:{' '}
                {selectedUser?.createdBy ? (
                  <StyledRowText $bold>{selectedUser.createdBy}</StyledRowText>
                ) : (
                  ''
                )}
              </StyledRowText>
            </StyledRow>
            <StyledRow>
              <StyledRowText>
                Modified at:{' '}
                {selectedUser?.updatedAt ? (
                  <StyledRowText $bold>
                    {dayjs(selectedUser.updatedAt).format('MMM DD, YYYY')}
                  </StyledRowText>
                ) : (
                  ''
                )}
              </StyledRowText>
            </StyledRow>
            <StyledRow>
              <StyledRowText>
                Modified by:{' '}
                {selectedUser?.updatedBy ? (
                  <StyledRowText $bold>{selectedUser.updatedBy}</StyledRowText>
                ) : (
                  ''
                )}
              </StyledRowText>
            </StyledRow>
          </Stack>
        </ModalContent>
      </Modal>

      <Modal
        open={showDeleteDialog}
        maxWidth="xs"
        title="Delete user"
        subtitle="The deleted user will no longer have access to log in to Zazmic-Connect."
        onClose={() => {
          setShowDeleteDialog(false);
          setShowDetailsDialog(true);
        }}
      >
        <>
          <ModalContent>
            <Stack>
              <ModalContentText>
                Full name: {selectedUser?.name} {selectedUser?.surname}
              </ModalContentText>
              <ModalContentText>Email: {selectedUser?.email}</ModalContentText>
              <ModalContentText>
                Role: {getUserRoleLabel(selectedUser?.userType as string)}
              </ModalContentText>
            </Stack>
          </ModalContent>
          <ModalActions>
            <ModalCloseButton
              autoFocus
              variant="outlined"
              onClick={() => {
                setShowDeleteDialog(false);
                setShowDetailsDialog(true);
              }}
            >
              Cancel
            </ModalCloseButton>
            <ModalSubmitButton variant="contained" onClick={showCaptchaDialog}>
              Delete
            </ModalSubmitButton>
          </ModalActions>
        </>
      </Modal>

      <Modal
        padding24
        open={showRoleDialog}
        maxWidth="xs"
        title="Change user role"
        subtitle="Please select another role for the user. A user with a different role will have different permissions in the Zazmic-Connect admin panel."
        onClose={() => {
          setShowRoleDialog(false);
          setShowDetailsDialog(true);
        }}
        sx={{
          '& .MuiPaper-root': {
            gap: '8px !important',
          },
        }}
      >
        <>
          <ModalContent>
            <TextField
              fullWidth
              select
              size="small"
              margin="normal"
              label="User Role"
              InputLabelProps={{
                shrink: true,
              }}
              value={selectedRole.value}
              error={!!selectedRole.error}
              helperText={selectedRole.error}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSelectedRole({
                  value: event.target.value as string,
                  error: '',
                });
              }}
              sx={{
                mb: '16px',
                '& .MuiInputBase-root': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '18px',
                },
                '& legend': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '12px',
                  fontWeight: 400,
                },
              }}
            >
              {roles.map((option) => (
                <MenuItem key={option} value={option}>
                  {getRoleLabel(option)}
                </MenuItem>
              ))}
            </TextField>
          </ModalContent>
          <ModalActions $padding24>
            <ModalCloseButton
              autoFocus
              variant="outlined"
              onClick={() => {
                setShowRoleDialog(false);
                setShowDetailsDialog(true);
                setSelectedRole({ value: '', error: '' });
              }}
            >
              Cancel
            </ModalCloseButton>
            <ModalSubmitButton variant="contained" onClick={handleChangeRole}>
              CHANGE
            </ModalSubmitButton>
          </ModalActions>
        </>
      </Modal>

      {showCaptcha ? (
        <CaptchaModal onComplete={deleteMethod} onClose={hideCaptcha} />
      ) : (
        <span />
      )}
    </>
  );
};

export default RolesTable;
