import React, { ReactElement } from 'react';
import { Container, Typography, Divider } from '@mui/material';
import { PageHeaderWrapper } from 'templates/PageWrapper/PageWrapper.styles';

type PageWrapperProps = {
  title: string;
  isRelative?: boolean;
  headerRight?: JSX.Element[] | JSX.Element;
  children: JSX.Element[] | JSX.Element;
};

type MobilePageWrapperProps = {
  title?: string | JSX.Element;
  children: JSX.Element[] | JSX.Element;
};

type PageWrapperMinWidthProps = {
  title: string | JSX.Element;
  headerRight?: JSX.Element[] | JSX.Element;
  alignItems?: string;
  children: JSX.Element[] | JSX.Element;
};

const PageWrapper = ({
  title,
  headerRight,
  isRelative,
  children,
}: PageWrapperProps) => (
  <Container
    sx={{
      minHeight: 'calc(100vh - 175px)',
      ...(isRelative ? { position: 'relative' } : {}),
    }}
  >
    <PageHeaderWrapper>
      <Typography variant="h1" my={3}>
        {title}
      </Typography>
      <div style={{ transform: 'translateY(-10px)' }}>{headerRight}</div>
    </PageHeaderWrapper>
    <Divider sx={{ marginBottom: 2 }} />
    {children}
  </Container>
);

export const MobilePageWrapper = ({
  title,
  children,
}: MobilePageWrapperProps) => (
  <Container sx={{ minHeight: 'calc(100vh - 125px)', padding: 0 }}>
    {title && <PageHeaderWrapper>{title}</PageHeaderWrapper>}
    {children}
  </Container>
);

export const PageWrapperMinWidth = ({
  title,
  headerRight,
  alignItems,
  children,
}: PageWrapperMinWidthProps) => (
  <Container
    sx={{
      minHeight: 'calc(100vh - 175px)',
    }}
  >
    <PageHeaderWrapper alignItems={alignItems}>
      <Typography className="page-title text-gray" variant="h1" my={3}>
        {title}
      </Typography>
      <div style={{ transform: 'translateY(-10px)' }}>{headerRight}</div>
    </PageHeaderWrapper>
    <Divider sx={{ marginBottom: 2 }} />
    {children}
  </Container>
);

export const HeadlessPageWrapper = ({
  children,
}: {
  children: ReactElement;
}) => (
  <Container
    sx={{
      minHeight: 'calc(100vh - 175px)',
      marginTop: '25px',
    }}
  >
    {children}
  </Container>
);

export default PageWrapper;
