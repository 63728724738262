import { createGlobalStyle } from 'styled-components';
import { breakpoints } from 'utils/styles/variables';
import { neutrals } from 'utils/styles/color';

export default createGlobalStyle`
  html {
    @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.sm}){
      font-size: 12px;
    }
    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md}){
      font-size: 14px;
    }

    body#body {
      overflow: hidden !important;
    }

    body::-webkit-scrollbar {
      display: none; /* Chrome, Safari, and newer Edge */
    }

    body {
      overflow: auto !important; // TODO: why we had to do it?
      padding: 0 !important;
      scrollbar-width: none; /* Firefox */

      & > iframe {
        display: none;
      }

      #root {
        min-height: 100vh;
      }

      .MuiAutocomplete-option {
        color: ${neutrals[700]};
        font-weight: 400;
      }
      .MuiAutocomplete-option.Mui-focused {
        background-color: #F6FAFF !important;
      }

      .calendar-paper .PrivatePickersSlideTransition-root {
        min-width: 296px;
        min-height: 220px;
      }

      .MuiAutocomplete-noOptions {
        font-family: Poppins, sans-serif;
        font-size: 0.75rem;
        font-weight: 400;
        color: ${neutrals[500]};
        text-align: center;
      }
    }
  }

  /* TODO: Monitor behaviour of scrollbar */
  /* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

  /* total width */
  body::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  body::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* scrollbar itself */
  body::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  body::-webkit-scrollbar-button {
    display:none;
  }
  
  body {
    margin: 0;
    padding: 0;
    background: #FDFCFA;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    @media (max-width: 600px) {
      background: #FFFFFF;
      .mobile-modal .MuiDialog-container .MuiPaper-root {
        margin: 0 !important;
        width: 360px;
        min-width: 360px;
      }
    }
  }

  hr {
    border: 1px solid #ECEDEF;
  }
  
  a {
    color: inherit;
  }
`;
