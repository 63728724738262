import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import useLogout from 'utils/hooks/useLogout';
import { useBaoSelector } from 'utils/hooks/redux';
import {
  ADMIN,
  isAnyAdmin,
  SALES_REP,
  SUPER_ADMIN,
} from 'utils/constants/roles';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';
import { neutrals } from 'utils/styles/color';

import CompanySwitch from 'molecules/CompanySwitch';
// import Circle from 'utils/styles/Circle.styles';
import {
  Stack,
  Container,
  Link,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Grow,
  MenuItem,
  Divider,
} from '@mui/material';

import {
  StyledHeader,
  MenuWrapper,
  MobileWrapper,
  StyledIconButton,
  StyledMenuList,
  StyledHeaderContent,
  StyledLogoText,
  StyledLogoContainer,
  StyledHeaderMenu,
} from './Header.styles';
import Logo from 'utils/styles/Logo.styles';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as PaymentsOutlinedIcon } from 'assets/icons/payment-settings.svg';
import { ReactComponent as AccountsOutlinedIcon } from 'assets/icons/account-settings.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/menu-settings.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/humburger.svg';
import { ReactComponent as LogIcon } from 'assets/icons/logs.svg';
import { palette } from 'utils/styles/variables';

const Header = () => {
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const logout = useLogout();
  const { width } = useWindowDimensions();
  const { value: user } = useBaoSelector((state) => state.user);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const outsideClick = (event: MouseEvent) => {
    if (
      event.target instanceof Node &&
      !anchorRef.current?.contains(event.target) &&
      open
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', outsideClick);

    return () => window.removeEventListener('mousedown', outsideClick);
  });

  const isSuperAdmin = user.userType === SUPER_ADMIN;
  const isAdmin = user.userType === ADMIN;
  const isSalesRep = user.userType === SALES_REP;

  const dashboardPage = location.pathname.startsWith('/dashboard');
  const invoicesPage = location.pathname.startsWith('/invoices');
  const companiesPage = location.pathname.startsWith('/companies');
  const contactsPage = location.pathname.startsWith('/contacts');
  const coreportsPage = location.pathname.startsWith('/coreports');
  const settingsPage = location.pathname.startsWith('/settings');
  const accountPage = location.pathname.startsWith('/account');
  const paymentPage = location.pathname.startsWith('/payment');
  const logsPage = location.pathname.startsWith('/logs');
  const projectsPage = location.pathname.startsWith('/projects');

  // useEffect(() => {
  //   if (isSuperAdmin || isAdmin) return; // no need to fetch payment methods for admins
  //   if (!currentCompany) return; // if no company selected no need to fetch payment methods
  //   if (user && user.userType && user.accessToken !== '') {
  //     setTimeout(() => {
  //       fetchPaymentMethods({ currentCompany, customer: true }).then(
  //         (response) => {
  //           const mainPaymentMethod = response.find((p: PMethod) => p.isMain);
  //           if (
  //             response.length === 2 &&
  //             !mainPaymentMethod &&
  //             !location.pathname.startsWith('/payment')
  //           ) {
  //             window.location.href = `${window.location.origin}/payment`;
  //           }
  //         }
  //       );
  //     }, 500);
  //   }
  // }, [location, user, currentCompany]);

  const isCompanySwitch = (() => {
    if (isAnyAdmin(user?.userType)) return false;
    return user.companies && user.companies.length > 1;
  })();

  return (
    <StyledHeader>
      <Container>
        <StyledHeaderContent>
          <Stack
            direction="row"
            alignItems="center"
            gap={width >= 600 ? '32px' : '16px'}
          >
            <StyledLogoContainer>
              <a
                href="https://www.zazmic.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Logo />
              </a>
              <StyledLogoText>Connect</StyledLogoText>
            </StyledLogoContainer>

            {isCompanySwitch && <CompanySwitch companies={user.companies} />}
          </Stack>
          <StyledHeaderMenu $admin={isAdmin || isSuperAdmin}>
            {!isSalesRep && (
              <Link
                className={dashboardPage ? 'active' : ''}
                component={RouterLink}
                to="/dashboard"
              >
                Dashboard
              </Link>
            )}
            <Link
              className={invoicesPage ? 'active' : ''}
              component={RouterLink}
              to="/invoices"
            >
              Invoices
            </Link>
            {(isSuperAdmin || isAdmin) && (
              <Link
                className={companiesPage ? 'active' : ''}
                component={RouterLink}
                to="/companies"
              >
                Companies
              </Link>
            )}
            {(isSuperAdmin || isAdmin) && (
              <Link
                className={contactsPage ? 'active' : ''}
                component={RouterLink}
                to="/contacts"
              >
                Contacts
              </Link>
            )}
            {(isSuperAdmin || isAdmin) && (
              <Link
                className={projectsPage ? 'active' : ''}
                component={RouterLink}
                to="/projects"
              >
                Projects
              </Link>
            )}
            <Link
              className={coreportsPage ? 'active' : ''}
              component={RouterLink}
              to="/coreports"
            >
              {/* RESELL-2239 */}
              {isAnyAdmin(user?.userType) ? 'Reports' : 'CloudOps'}
            </Link>
          </StyledHeaderMenu>
          <MenuWrapper>
            <StyledIconButton
              disableRipple
              aria-label="profile menu"
              id="menu-icon"
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              $fill={open ? palette.error.main : undefined}
            >
              <MenuIcon />
            </StyledIconButton>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              transition
              placement="bottom-end"
              style={{ zIndex: 10 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper sx={{ boxShadow: '0px 1px 3px 0px #999999' }}>
                    <StyledMenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      $isMobile={width < 600}
                    >
                      <MobileWrapper>
                        {!isSalesRep && (
                          <MenuItem>
                            <Link component={RouterLink} to="/dashboard">
                              <ListItemText
                                className={dashboardPage ? 'active' : ''}
                              >
                                Dashboard
                              </ListItemText>
                            </Link>
                          </MenuItem>
                        )}
                        <MenuItem>
                          <Link component={RouterLink} to="/invoices">
                            <ListItemText
                              className={invoicesPage ? 'active' : ''}
                            >
                              Invoices
                            </ListItemText>
                          </Link>
                        </MenuItem>
                        {isSuperAdmin && (
                          <MenuItem>
                            <Link component={RouterLink} to="/companies">
                              <ListItemText
                                className={companiesPage ? 'active' : ''}
                              >
                                Companies
                              </ListItemText>
                            </Link>
                          </MenuItem>
                        )}
                        {isSuperAdmin && (
                          <MenuItem>
                            <Link component={RouterLink} to="/contacts">
                              <ListItemText
                                className={contactsPage ? 'active' : ''}
                              >
                                Contacts
                              </ListItemText>
                            </Link>
                          </MenuItem>
                        )}
                        {isSuperAdmin && (
                          <MenuItem>
                            <Link component={RouterLink} to="/projects">
                              <ListItemText
                                className={projectsPage ? 'active' : ''}
                              >
                                Projects
                              </ListItemText>
                            </Link>
                          </MenuItem>
                        )}
                        <MenuItem>
                          <Link component={RouterLink} to="/coreports">
                            <ListItemText
                              className={coreportsPage ? 'active' : ''}
                            >
                              {/* RESELL-2239 */}
                              {isAnyAdmin(user?.userType)
                                ? 'Reports'
                                : 'CloudOps'}
                            </ListItemText>
                          </Link>
                        </MenuItem>
                        {!isSuperAdmin && !isAdmin && (
                          <Divider
                            sx={{
                              borderBottomWidth: 1,
                              margin: '0 !important',
                            }}
                          />
                        )}
                      </MobileWrapper>
                      {(isSuperAdmin || isAdmin) && (
                        <MenuItem>
                          <Link component={RouterLink} to="/settings">
                            <ListItemIcon>
                              <SettingsIcon
                                color={
                                  settingsPage
                                    ? palette.error.main
                                    : neutrals[800]
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              className={settingsPage ? 'active' : ''}
                            >
                              Settings
                            </ListItemText>
                          </Link>
                        </MenuItem>
                      )}
                      {!isAnyAdmin(user?.userType) && (
                        <MenuItem>
                          <Link component={RouterLink} to="/payment">
                            <ListItemIcon>
                              <PaymentsOutlinedIcon
                                color={
                                  paymentPage
                                    ? palette.error.main
                                    : neutrals[800]
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              className={paymentPage ? 'active' : ''}
                            >
                              Payment settings
                            </ListItemText>
                          </Link>
                        </MenuItem>
                      )}
                      {(isSuperAdmin || isAdmin) && (
                        <MenuItem>
                          <Link component={RouterLink} to="/logs">
                            <ListItemIcon>
                              <LogIcon
                                color={
                                  logsPage ? palette.error.main : neutrals[800]
                                }
                              />
                            </ListItemIcon>
                            <ListItemText className={logsPage ? 'active' : ''}>
                              Logs
                            </ListItemText>
                          </Link>
                        </MenuItem>
                      )}
                      {!isSalesRep && (
                        <Divider
                          sx={{ borderBottomWidth: 1, margin: '0 !important' }}
                        />
                      )}
                      {user?.name && (
                        <MenuItem disabled>
                          <ListItemText>
                            {user?.name} {user?.surname || ''}
                          </ListItemText>
                        </MenuItem>
                      )}
                      <MenuItem sx={{ height: 36 }}>
                        <Link component={RouterLink} to="/account">
                          <ListItemIcon>
                            <AccountsOutlinedIcon
                              color={
                                accountPage ? palette.error.main : neutrals[800]
                              }
                            />
                          </ListItemIcon>
                          <ListItemText className={accountPage ? 'active' : ''}>
                            Account Details
                          </ListItemText>
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={() => logout()} sx={{ height: 36 }}>
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                      </MenuItem>
                    </StyledMenuList>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </MenuWrapper>
        </StyledHeaderContent>
      </Container>
    </StyledHeader>
  );
};

export default Header;
