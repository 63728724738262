import styled from 'styled-components';
import { TableContainer, TableCell, TableRow, Stack } from '@mui/material';
import { neutrals, reds } from 'utils/styles/color';

const StyledTableContainer = styled(TableContainer)<{
  $new?: boolean;
}>`
  && {
    overflow-x: hidden;
    width: ${({ $new }) => ($new ? '100%' : 'calc(100% - 24px)')};
    padding: ${({ $new }) => ($new ? '0' : '0 12px')};
  }
`;

const StyledTableRow = styled(TableRow)<{
  $headerRow?: boolean;
  $active?: boolean;
  $collapsible?: boolean;
}>`
  && {
    background-color: ${({ $active }) => ($active ? '#F6FAFF' : 'inherit')};
    cursor: ${({ $collapsible }) => ($collapsible ? 'pointer' : 'inherit')};
  }
`;

const StyledTableRowCollapse = styled(TableRow)`
  && {
    background: #fdfdfd;
  }
`;

const StyledTableHeadCell = styled(TableCell)<{
  $borderless?: boolean;
}>`
  && {
    padding: ${({ $borderless }) => ($borderless ? '8px 0' : '8px')};
  }
`;

const StyledTableCell = styled(TableCell)<{
  $size?: 'small' | 'medium';
  $borderless?: boolean;
}>`
  && {
    ${({ $size, $borderless }) => {
      if ($borderless) {
        return {
          padding: '8px 0',
          height: '16px',
        };
      }
      return $size === 'small'
        ? {
            padding: '8px',
            height: '16px',
          }
        : {
            padding: '12px 8px',
            height: '32px',
          };
    }};

    overflow: hidden;
    color: ${neutrals[700]};
    text-overflow: ellipsis;
    /** position: relative; */

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    border-bottom: ${({ $borderless }) =>
      $borderless ? 'none' : '1px solid rgba(224, 224, 224, 1) !important'};

    &:last-child td {
      border-bottom: ${({ $borderless }) =>
        $borderless ? 'none' : '1px solid rgba(224, 224, 224, 1) !important'};
    }
  }
`;

const StyledCellContainer = styled(Stack)<{
  $hStack?: boolean;
  $vStack?: boolean;
  $disabled?: boolean;
  $pr?: string;
  $spaceBetween?: boolean;
  $right?: boolean;
  $left?: boolean;
  $size?: 'small' | 'medium';
  $display?: 'flex' | 'block' | 'inline';
  $gap?: string;
}>`
  && {
    ${({ $hStack, $size }) =>
      $hStack && {
        display: 'flex',
        flexDirection: 'row',
        gap: $size ? '16px' : '32px',
        justifyContent: 'flex-end',
        alignItems: 'center',

        '.MuiBox-root': {
          display: 'flex',
          justifyContent: 'center',
          width: '20px',
        },

        '.MuiButtonBase-root': {
          padding: 0,
          backgroundColor: 'transparent',
        },
      }}

    ${({ $vStack, $right }) =>
      $vStack &&
      $right && {
        alignItems: 'flex-end',
      }}

    ${({ $hStack, $right }) =>
      $hStack &&
      $right && {
        justifyContent: 'flex-end',
      }}

    ${({ $hStack, $left }) =>
      $hStack &&
      $left && {
        justifyContent: 'flex-start',
      }}

    ${({ $disabled }) =>
      $disabled && {
        color: reds[700],
      }}

    ${({ $pr }) =>
      $pr && {
        paddingRight: $pr,
      }}

    ${({ $spaceBetween }) =>
      $spaceBetween && {
        justifyContent: 'space-between',
      }}

    ${({ $display }) =>
      $display && {
        display: $display,
      }}

    ${({ $gap }) =>
      $gap && {
        gap: $gap,
      }}
  }
`;

export {
  StyledTableContainer,
  StyledTableRow,
  StyledTableRowCollapse,
  StyledTableHeadCell,
  StyledTableCell,
  StyledCellContainer,
};
