import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Paper, Tab, Stack } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

import { StyledTabList } from 'molecules/Card/Card.styles';
import InvoicesTable from 'organisms/InvoicesTable/InvoicesTable';
import InvoiceStatusSelect from 'atoms/StatusSelect/InvoiceStatusSelect';
import { statusType } from 'utils/constants/invoiceStatus';
import { companyType } from 'pages/CompanyPage/types';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import { StyledTabPanel } from './CompanyInvoicesTabs.styles';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import { getCloudOpsReports } from 'store/cloudOps/cloudOpsSlice';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import CloudOpsReportAdminTable from 'organisms/CloudOpsReportAdmin/CloudOpsReportAdminTable';
import { StyledFilterWrapper } from 'molecules/Filter/Filter.styles';
import FilterDetails from 'molecules/Filter/FilterDetails';
import Block from 'molecules/Card';
import { storeInvoicesInfo } from 'store/invoices/invoicesSlice';
import SalesReport from 'organisms/SalesReport';
import axios, { AxiosResponse } from 'axios';
import { SalesReportRow } from 'organisms/SalesReportTable';

type TabType = 'invoices' | 'cloudops' | 'salesRep';

const CompanyInvoicesTabs: FC<{ company: companyType }> = ({ company }) => {
  const [statusFilter, setStatusFilter] = useState<statusType>('all');
  const [selectedTab, setSelectedTab] = useState<TabType>('invoices');
  const [salesReps, setSalesReps] = useState<SalesReportRow[]>([]);

  const { hsId } = useParams() as { hsId: string };

  const dispatch = useBaoDispatch();
  const { invoices, cloudOps } = useBaoSelector((state) => state);

  const invoiceDetails = useMemo(() => {
    const { totalCount, totalAmount } = invoices;
    return [
      { label: 'Invoices', value: totalCount ? Number(totalCount) : '0' },
      {
        label: 'Total',
        value: totalAmount
          ? currencyFormatter(Number(totalAmount), 'usd')
          : '$0.00',
      },
    ];
  }, [invoices]);

  const handleChangeTabs = useCallback(
    (event: React.SyntheticEvent, newValue: TabType) => {
      setSelectedTab(newValue);
    },
    []
  );

  useEffect(() => {
    (async () => {
      dispatch(getCloudOpsReports({ company_hs_id: hsId, limit: 20, page: 0 }));
      dispatch(
        storeInvoicesInfo({
          customer: undefined,
          period: undefined,
          newPage: 0,
          rowsPerPage: 20,
          sort: 'sort[created_at]=desc',
          status: 'all',
          company: hsId,
          search: null,
        })
      );
      const response: AxiosResponse<{
        deals: SalesReportRow[];
        count: number;
      }> = await axios.get(
        `${process.env.REACT_APP_REPORT_SERVICE}/deal/search`,
        {
          params: {
            company_hs_ids: hsId,
            page: 1,
            per_page: 20,
          },
        }
      );

      setSalesReps(response?.data?.deals || []);
    })();
  }, [hsId]);

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  return (
    <Paper sx={{ pt: cloudOps.projects_reports.length ? '16px' : 0 }}>
      {cloudOps.projects_reports.length || salesReps.length ? (
        <TabContext value={selectedTab}>
          <StyledTabList
            onChange={handleChangeTabs}
            aria-label="Company Details and Contacts tabs"
            variant="fullWidth"
          >
            <Tab
              label="Invoices"
              {...a11yProps(0)}
              className={selectedTab === 'invoices' ? '.active' : ''}
              value="invoices"
            />
            {cloudOps.projects_reports.length && (
              <Tab
                label="CloudOps"
                {...a11yProps(1)}
                className={selectedTab === 'cloudops' ? '.active' : ''}
                value="cloudops"
              />
            )}
            {salesReps.length && (
              <Tab
                label="Sales Rep"
                {...a11yProps(1)}
                className={selectedTab === 'salesRep' ? '.active' : ''}
                value="salesRep"
              />
            )}
          </StyledTabList>
          <StyledTabPanel
            value="invoices"
            sx={{
              padding: '0',
              // FIXME: controls rendering of InvoiceStatusSelect dropdown
              position: 'relative',
              '.invoice-status-select .MuiPaper-root': {
                top: '45px !important',
                left: '40px !important',
              },
            }}
          >
            <StyledFilterWrapper>
              <InvoiceStatusSelect
                onChange={(e) => setStatusFilter(e)}
                isComp
              />

              <FilterDetails
                show={!invoices.isLoading && statusFilter !== 'all'}
                data={invoiceDetails}
              />
            </StyledFilterWrapper>
            <InvoicesTable
              status={statusFilter}
              onError={() => null}
              company={hsId || ''}
            />
          </StyledTabPanel>
          <TabPanel value="cloudops" sx={{ p: 0 }}>
            <CloudOpsReportAdminTable company={company} />
          </TabPanel>
          <TabPanel value="salesRep" sx={{ p: 0 }}>
            <SalesReport hsId={hsId} />
          </TabPanel>
        </TabContext>
      ) : (
        <Block
          avatar={<CardAvatar />}
          title="Invoices totals by months"
          sx={{
            '& .MuiCardContent-root': {
              p: '0 !important',
            },
          }}
        >
          <Stack
            sx={{
              // marginRight: 0,
              // FIXME: controls rendering of InvoiceStatusSelect dropdown
              position: 'relative',
              '.MuiPaper-root': {
                top: '45px !important',
                left: '40px !important',
              },
            }}
          >
            <StyledFilterWrapper>
              <InvoiceStatusSelect
                onChange={(e) => setStatusFilter(e)}
                isComp
              />

              <FilterDetails
                show={!invoices.isLoading && statusFilter !== 'all'}
                data={invoiceDetails}
              />
            </StyledFilterWrapper>
            <InvoicesTable
              status={statusFilter}
              onError={() => null}
              company={hsId || ''}
            />
          </Stack>
        </Block>
      )}
    </Paper>
  );
};

export default CompanyInvoicesTabs;
