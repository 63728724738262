import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
  Backdrop,
  Box,
  CardHeader,
  CircularProgress,
  FormControl,
  Tooltip,
} from '@mui/material';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import { StyledPayButton } from 'organisms/PaymentOptionCard/PaymentOptionCard.styles';
import ButtonMenu from 'molecules/ButtonMenu';
import Table from 'molecules/Table';
import AccountNotVerifiedModal from 'molecules/PaymentOptionRadio/components/AccountNotVerifiedModal';
import Modal from 'molecules/Modal';
import {
  ModalActions,
  ModalCloseButton,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import {
  StyledCellContainer,
  StyledTableContainer,
} from 'molecules/Table/styles';

import { fetchPaymentMethods } from 'molecules/PaymentOptionRadio/helpers';
import { getPaymentIcon } from 'organisms/PaymentOptionCard/helpers';
import { useBaoSelector } from 'utils/hooks/redux';
import dayjs from 'dayjs';
import axios from 'axios';
import { neutrals } from 'utils/styles/color';

import { ReactComponent as InfoIcon } from 'assets/icons/new_info.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg';
import { ReactComponent as AccountNotVerifiedIcon } from 'assets/icons/account-not-verified.svg';

const PaymentOptionCard: FC<{
  mainPaymentMethod?: PMethod | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stateChanger?: any;
  onDialogOpen: (o: boolean) => void;
}> = ({ mainPaymentMethod, stateChanger, onDialogOpen }) => {
  const [paymentMethods, setPaymentMethods] = useState<PMethod[]>([]);
  const [prevChangeData, setPrevChangeData] = useState<PMethod[]>([]);
  const [deletingPaymentMethod, setDeletingPaymentMethod] = useState(false);
  const [deletingId, setDeletingId] = useState<string>('');
  const [isNotVerifiedModal, setIsNotVerifiedModal] = useState<boolean>(false);
  const [selectingPayment, setSelectingPayment] = useState(false);
  const [cardChange, setCardChange] = useState<string>('');
  const [achChange, setAchChange] = useState<string>('');
  const [paymentMethodIdBeingConfirmed, setPaymentMethodIdBeingConfirmed] =
    useState('');
  const [openLoadingSpinner, setOpenLoadingSpinner] = useState(false);
  const [discount, setDiscount] = useState(0);

  const { currentCompany } = useBaoSelector((state) => state.common);

  const getOptions = (method: PMethod) => [
    {
      icon: <DeleteIcon />,
      label: 'Remove payment method',
      value: 'delete',
      disabled: method?.isMain,
    },
  ];

  const handleSelect = useCallback(
    (row: PMethod, key: string) => {
      if (key === 'delete') {
        setDeletingPaymentMethod(true);
        setDeletingId(row?.id);
      }
    },
    [paymentMethods]
  );

  const handleClickNotVerified = useCallback(() => {
    setIsNotVerifiedModal((prev) => !prev);
  }, []);

  const deleteMethod = useCallback(
    (id: string) => {
      handleDelete(id);
      setDeletingPaymentMethod(false);
    },
    [currentCompany, mainPaymentMethod, paymentMethods]
  );

  const methodChangePopupConfirm = () => {
    const paymentMethodList = [...paymentMethods];
    const id = cardChange || achChange;
    paymentMethodList.forEach((i) => {
      i.isMain = id === i.id;
    });
    setPaymentMethods(paymentMethodList);

    if (id && typeof id === 'string') {
      setPrevChangeData(paymentMethodList);
      setSelectingPayment(false);
      setPaymentMethodIdBeingConfirmed('');
    }

    setPrevChangeData([...paymentMethods]);
    setCardChange('');
    setAchChange('');
  };

  const methodChangePopupCancel = () => {
    const dataPayments = [...prevChangeData];
    setPaymentMethods(dataPayments);
    setCardChange('');
    setAchChange('');
  };

  const handleSelectPaymentMethod = async (id: string) => {
    setSelectingPayment(true);

    if (!currentCompany) return;

    setOpenLoadingSpinner(true);

    await axios
      .patch(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/update-main-payment-method`,
        {
          id,
          company_hs_id: currentCompany?.company_hs_id,
        }
      )
      .then(() =>
        fetchPaymentMethods({ currentCompany }).then((response) => {
          setPaymentMethods(response);

          setSelectingPayment(false);
          setPaymentMethodIdBeingConfirmed('');
          setOpenLoadingSpinner(false);
        })
      );
  };

  const handleDelete = async (id: string) => {
    if (!currentCompany) return;

    if (mainPaymentMethod?.id === id) {
      await axios.patch(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/update-payment-settings`,
        {
          is_auto_payment: false,
          company_hs_id: currentCompany.company_hs_id,
        }
      );
    }

    // todo add try/catch
    await axios.delete(
      `${process.env.REACT_APP_BILLING_SERVICE}/payment/payment-method/${id}`
    );

    fetchPaymentMethods({ currentCompany }).then((response) => {
      setPaymentMethods(response);
      stateChanger(response.length);
    });
  };

  const columns = useMemo(
    () => [
      {
        key: 'createdAt',
        label: 'Date added',
        sortable: false,
        width: '20%',
        render: ({ createdAt }: PMethod) => (
          <StyledCellText>
            {dayjs(dayjs(new Date(createdAt))).format('MMM DD, YYYY')}
          </StyledCellText>
        ),
      },
      {
        key: 'credit_card_info',
        label: 'Type',
        sortable: false,
        width: '10%',
        render: (method: PMethod) => {
          const { image } = getPaymentIcon(method);
          return <StyledCellContainer>{image}</StyledCellContainer>;
        },
      },
      {
        key: 'payment_method',
        label: 'Details',
        sortable: false,
        width: '30%',
        render: (method: PMethod) => {
          const { name, last4, expire } = getPaymentIcon(method);
          return (
            <StyledCellContainer>
              <div className="payment-option-info">
                <StyledCellText>
                  {name}{' '}
                  {expire !== '' ? (
                    <span>&bull;&bull;&bull;&bull; {last4}</span>
                  ) : (
                    ''
                  )}
                </StyledCellText>
                <StyledCellText sx={{ fontSize: '11px !important' }}>
                  {expire !== '' ? (
                    expire
                  ) : (
                    <span>&bull;&bull;&bull;&bull; {last4}</span>
                  )}
                </StyledCellText>
              </div>
            </StyledCellContainer>
          );
        },
      },
      {
        key: 'feeFormulae',
        label: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            Transaction fee details
            <Tooltip title="Transaction fees are applied by Stripe for processing payments. These fees vary based on your payment method and transaction details.">
              <span style={{ height: '18px', cursor: 'pointer' }}>
                <InfoIcon color={neutrals[300]} />
              </span>
            </Tooltip>
          </Box>
        ),
        sortable: false,
        width: '30%',
        render: ({ feeFormulae }: PMethod) => (
          <StyledCellText>{feeFormulae || ''}</StyledCellText>
        ),
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        width: '10%',
        render: (method: PMethod) => (
          <StyledCellContainer $hStack $right $gap="16px">
            {method.paymentMethod !== 'card' && method.isVerified === false && (
              <AccountNotVerifiedIcon
                style={{ cursor: 'pointer' }}
                onClick={handleClickNotVerified}
              />
            )}
            <ButtonMenu
              options={getOptions(method)}
              onSelect={(k: string) => handleSelect(method, k)}
            />
          </StyledCellContainer>
        ),
      },
    ],
    [mainPaymentMethod, paymentMethods]
  );

  useEffect(() => {
    if (!currentCompany) return;

    fetchCardData();

    axios
      .get(
        `${process.env.REACT_APP_USER_SERVICE}/company/${currentCompany.company_hs_id}`
      )
      .then((p) => setDiscount(p.data.discount));
  }, [currentCompany]);

  function fetchCardData() {
    fetchPaymentMethods({ currentCompany }).then((response) => {
      setPaymentMethods(response);
      setPrevChangeData([...response]);
    });
  }

  return (
    <>
      <FormControl fullWidth>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height={80}
          p="0 12px"
        >
          <CardHeader
            style={{ padding: 0 }}
            title="Payment methods"
            avatar={<CardAvatar />}
          />
          <StyledPayButton
            size="medium"
            variant="text"
            onClick={() => onDialogOpen(true)}
          >
            Add payment method
          </StyledPayButton>
        </Box>
        <Box p="0 12px">
          <StyledTableContainer
            $new
            sx={{
              position: 'relative',
              minHeight: 216,
              '.MuiTableBody-root .MuiTableRow-root:last-of-type': {
                'th, td': { borderBottom: 'none !important' },
              },
            }}
          >
            <Table
              aria-disabled={selectingPayment}
              idKey="id"
              columns={columns}
              data={paymentMethods || []}
            />
            <Box sx={{ height: '56px' }} />
          </StyledTableContainer>
        </Box>
      </FormControl>

      <Modal
        open={deletingPaymentMethod}
        maxWidth="sm"
        title="Remove payment method?"
        subtitle="Once removed, it will no longer be available for manual invoice payments or auto payments. If this payment method is currently selected for auto payment, auto payment will be disabled automatically."
        onClose={() => setDeletingPaymentMethod(false)}
      >
        <ModalActions>
          <ModalCloseButton
            autoFocus
            variant="outlined"
            onClick={() => setDeletingPaymentMethod(false)}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            autoFocus
            size="medium"
            variant="contained"
            onClick={() => deleteMethod(deletingId)}
          >
            Confirm
          </ModalSubmitButton>
        </ModalActions>
      </Modal>

      {/* if the CC is checked */}
      <Modal
        open={cardChange.length > 0}
        maxWidth="xs"
        title="Payment method change"
        subtitle={`
          A ${discount}% invoice discount from Zazmic will be applied when the
          payment method is Bank Transfer. Continue?
        `}
        onClose={methodChangePopupCancel}
        sx={{ zIndex: 32000 }}
      >
        <ModalActions>
          <ModalCloseButton
            autoFocus
            variant="outlined"
            onClick={() => methodChangePopupCancel()}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            size="medium"
            variant="contained"
            onClick={() => methodChangePopupConfirm()}
          >
            Confirm
          </ModalSubmitButton>
        </ModalActions>
      </Modal>

      {/* if the ACH is checked */}
      <Modal
        open={achChange.length > 0}
        maxWidth="xs"
        title="Payment method change"
        subtitle={`
          Credit card payment method will remove the ${discount}% discount. This
          discount is valid only with Bank Transfer payment. Continue?
        `}
        onClose={methodChangePopupCancel}
        sx={{ zIndex: 32000 }}
      >
        <ModalActions>
          <ModalCloseButton
            autoFocus
            variant="outlined"
            onClick={() => methodChangePopupCancel()}
          >
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            size="medium"
            variant="contained"
            onClick={() => methodChangePopupConfirm()}
          >
            Confirm
          </ModalSubmitButton>
        </ModalActions>
      </Modal>

      <Modal
        open={paymentMethodIdBeingConfirmed !== ''}
        maxWidth="sm"
        className="mobile-modal"
        title="Auto payment method change"
        subtitle="Please confirm that you want to change payment method that will be used for auto payment."
        onClose={() => setPaymentMethodIdBeingConfirmed('')}
      >
        <>
          <ModalActions>
            <ModalCloseButton
              autoFocus
              variant="outlined"
              onClick={() => setPaymentMethodIdBeingConfirmed('')}
            >
              Cancel
            </ModalCloseButton>
            <ModalSubmitButton
              size="medium"
              variant="contained"
              onClick={() =>
                handleSelectPaymentMethod(paymentMethodIdBeingConfirmed)
              }
            >
              Confirm
            </ModalSubmitButton>
          </ModalActions>
        </>
      </Modal>

      <AccountNotVerifiedModal
        open={isNotVerifiedModal}
        onClose={handleClickNotVerified}
      />

      {openLoadingSpinner && (
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: 1400 }}
            open={openLoadingSpinner}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default PaymentOptionCard;
