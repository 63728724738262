import React, { FC, useMemo } from 'react';

import dayjs from 'dayjs';

import Table from 'molecules/Table';
import { StyledCellText2 } from 'molecules/Table/styles/Text.styles';
import { ICompanyDeal } from 'organisms/CompanyDetailsCard/index';
import { neutrals } from 'utils/styles/color';

const CompanyDeals: FC<{
  deals: ICompanyDeal[];
}> = ({ deals }) => {
  const columns = useMemo(
    () => [
      {
        key: 'sales_rep_name',
        label: 'Deal owner',
        sortable: false,
        width: '33%',
        render: ({ sale_rep_name }: ICompanyDeal) => (
          <StyledCellText2>{sale_rep_name}</StyledCellText2>
        ),
      },
      {
        key: 'close_date',
        label: 'Close Won Date',
        sortable: false,
        width: '33%',
        render: ({ close_date }: ICompanyDeal) => (
          <StyledCellText2>
            {close_date ? dayjs(dayjs(close_date)).format('MMM DD, YYYY') : ''}
          </StyledCellText2>
        ),
      },
      {
        key: 'status',
        label: 'Sales Rep report status',
        sortable: false,
        width: '33%',
        render: ({ status }: ICompanyDeal) => (
          <StyledCellText2>{status}</StyledCellText2>
        ),
      },
    ],
    []
  );

  return (
    <Table
      data={deals}
      columns={columns}
      idKey="id"
      sx={{
        minWidth: 650,
        '.MuiTableHead-root .MuiTypography-root': {
          color: `${neutrals[500]} !important`,
        },
      }}
      aria-label="simple table"
      size="small"
    />
  );
};

export default CompanyDeals;
