import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import InvoicesReducer from './invoices/invoicesSlice';
import PayInvoiceReducer from './payInvoice/payInvoiceSlice';
import CloudOpsReducer from './cloudOps/cloudOpsSlice';
import DealReducer from './deals/dealSlice';
import CommonReducer from './common/commonSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    invoices: InvoicesReducer,
    payInvoice: PayInvoiceReducer,
    cloudOps: CloudOpsReducer,
    deals: DealReducer,
    common: CommonReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
