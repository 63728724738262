import React, { useState, useCallback, useRef } from 'react';

import { Grid, Stack } from '@mui/material';
import PageWrapper from 'templates/PageWrapper/PageWrapper';
import LogTable, { initialValue } from 'organisms/LogTable';

import axios, { AxiosResponse } from 'axios';
import debounce from 'lodash-es/debounce';
import dayjs from 'dayjs';

import { FilterAutocomplete } from 'atoms/TableFilter';
import { StyledFilterWrapper } from 'molecules/Filter/Filter.styles';
import Block from 'molecules/Card';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import Filter from 'organisms/Filter';

import { IFilterOption } from 'organisms/Filter/types';

interface IParams {
  email?: string;
  page: number;
  limit: number;
  date_from?: string;
  date_to?: string;
}
type FilterOptions = {
  email_types: { id: string; name: string }[];
};
export type filterApplyType = {
  date_from: string;
  date_to: string;
  type: string;
};

const fetchList = async (name: string) => {
  const response: AxiosResponse<FilterOptions> = await axios.get(
    `${process.env.REACT_APP_NOTIFICATION_SERVICE}/emails/types`
  );

  let key: 'email_types' | '' = '';
  switch (name) {
    case 'subject':
      key = 'email_types';
      break;
    default:
      break;
  }

  if (!key) return [];

  return (response.data?.[key] || []) as { id: string; name: string }[];
};

const initialOptions: Array<IFilterOption> = [
  { code: 'sent_date', title: 'Date sent', active: true },
  { code: 'subject', title: 'Subject', fetchList },
];

const LogsPage = () => {
  const [multiFilter, setMultiFilter] = useState<Partial<filterApplyType>>({});
  const [email, setEmail] = useState<string>('');

  const searchRef = useRef<HTMLInputElement | null>(null);

  const handleFilterChange = useCallback(
    (nextFilterValue: { [key: string]: string[] }) => {
      const filterApply = Object?.entries(nextFilterValue).reduce(
        (acc, [key, value]) => {
          if (key === 'sent_date' && value && value[0] && value[1]) {
            acc.date_from = dayjs(dayjs(value[0])).format('YYYY-MM-DD');
            acc.date_to = dayjs(dayjs(value[1])).format('YYYY-MM-DD');
          }

          if (key === 'subject' && value[0]) {
            acc.type = value?.join(',');
          }

          return acc;
        },
        {} as filterApplyType
      );

      if (JSON.stringify(filterApply) === JSON.stringify(multiFilter)) return;

      setMultiFilter(filterApply);
    },
    [email, multiFilter]
  );

  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      setEmail(event.target.value);
    }, 300),
    []
  );

  const onReset = useCallback(() => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    setEmail('');
  }, []);

  const fetchPageData = useCallback(
    async (filter: typeof initialValue) => {
      const { page, limit } = filter;

      const params: IParams = { ...prepareParams(), page, limit };

      const { data } = await axios.get(
        `${process.env.REACT_APP_NOTIFICATION_SERVICE}/emails/filter`,
        { params }
      );
      const { emails: logs, count } = data;

      return { logs, count };
    },
    [email, multiFilter]
  );

  function prepareParams() {
    return {
      ...(email?.trim() ? { email } : {}),
      ...(multiFilter || {}),
    };
  }

  return (
    <PageWrapper title="Logs" isRelative>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Block
            avatar={<CardAvatar />}
            title="Notifications"
            sx={{ '.MuiCardContent-root': { p: '0 !important' } }}
          >
            <>
              <StyledFilterWrapper
                sx={{
                  '.filter-popover-container': {
                    top: '215px',
                    left: '30px',
                  },
                }}
              >
                <Stack direction="row" gap={3}>
                  <Filter
                    options={initialOptions}
                    onChange={handleFilterChange}
                  />

                  <FilterAutocomplete
                    isFreeSolo={!!email}
                    value={email}
                    options={[]}
                    placeholder="Search by: Email"
                    tooltip="Search by: Email"
                    onChange={debouncedChangeHandler}
                    onReset={onReset}
                  />
                </Stack>
              </StyledFilterWrapper>

              <LogTable
                fetch={fetchPageData}
                email={email}
                filter={multiFilter}
              />
            </>
          </Block>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default LogsPage;
