import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Box, Button, InputBase, Stack } from '@mui/material';
import {
  StyledListFilterButton,
  StyledListFilterHeader,
} from 'organisms/Filter/forms/Form.styles';
import { FilterContext } from 'organisms/Filter/index';
import loaderGif from 'assets/images/loaderGif.gif';
import { array_move } from 'utils/helpers/array';

const ListFilter: FC<{ name: string }> = ({ name }) => {
  const context = useContext(FilterContext);
  const { options, filter, setFilter } = context;

  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<string[]>(
    (filter?.[name] as string[]) || []
  );
  const [search, setSearch] = useState<string>('');
  const [data, setData] = useState<{ id: string; name: string }[]>([]);
  const [list, setList] = useState<{ id: string; name: string }[]>([]);

  const debouncedChangeHandler = useCallback(
    (event) => {
      const filtered = data.filter((o) =>
        o.name.toLowerCase().includes(event.target.value.toLowerCase())
      );

      setSearch(event.target.value);
      setList(filtered);
    },
    [checked, data]
  );

  const handleReset = useCallback(() => {
    setList(data);
    setSearch('');
    setChecked([]);
  }, [data]);

  const handleToggle = (id: string) => () => {
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    setFilter({ ...filter, [name]: checked });
  }, [checked]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const func = options?.find((o) => o.code === name)?.fetchList;
        if (func) {
          const response = await func(name);
          setData(response);

          const sorted = [...response];
          checked.forEach((id) => {
            array_move(
              sorted,
              sorted.findIndex((o) => o.id === id),
              0
            );
          });
          setList(sorted);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (!context) {
    throw new Error('ListFilter must be used within a FilterProvider');
  }

  return (
    <Stack>
      <StyledListFilterHeader>
        <InputBase
          sx={{ flex: 1 }}
          value={search}
          placeholder="Search"
          inputProps={{ 'aria-label': 'search items' }}
          onChange={debouncedChangeHandler}
        />
        <Button variant="text" onClick={handleReset}>
          Reset
        </Button>
      </StyledListFilterHeader>
      {loading ? (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <img src={loaderGif} alt="Data loading" />
        </Box>
      ) : (
        <List sx={{ width: '100%', height: 300, overflowY: 'scroll' }}>
          {list.map(({ id, name: Name }) => {
            const labelId = `checkbox-list-label-${id}`;

            return (
              <ListItem key={id} disablePadding>
                <StyledListFilterButton
                  dense
                  role={undefined}
                  onClick={handleToggle(id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={Name} />
                </StyledListFilterButton>
              </ListItem>
            );
          })}
        </List>
      )}
    </Stack>
  );
};

export default ListFilter;
