import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Card, CardHeader, Box, Typography, Stack } from '@mui/material';
import PageWrapper, {
  MobilePageWrapper,
} from 'templates/PageWrapper/PageWrapper';
import PaymentOptionRadio from 'molecules/PaymentOptionRadio/PaymentOptionRadio';
import AddPaymentMethodDialog from 'organisms/AddPaymentMethodDialog/AddPaymentMethodDialog';
import { AntSwitch } from 'atoms/AntSwitch';
import { fetchPaymentMethods } from 'molecules/PaymentOptionRadio/helpers';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import PaymentMethod from 'molecules/PaymentMethod/PaymentMethod';
import { CaptchaModal } from 'molecules/CaptchaModal/CaptchaModal';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';
import { SUPER_ADMIN } from 'utils/constants/roles';
import { MobileInvoicesTitle } from 'utils/styles/Typography.styles';
import { useBaoSelector } from 'utils/hooks/redux';
import {
  Container,
  MobilePaymentSettingsContent,
  MobilePaymentTermsContent,
  StyledAutoPaymentLabel,
  StyledAutoPaymentText,
} from './PaymentPage.styles';
import {
  ContainedBtn,
  OutlinedBtn,
} from 'pages/AccountPage/AccountPage.styles';
import Modal from 'molecules/Modal';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { ModalContentText } from 'molecules/Modal/ModalText.styles';
import Link from 'atoms/Link/Link';
import PaymentOptionCard from 'organisms/PaymentOptionCard';
import { palette } from 'utils/styles/variables';
import SelectAutoPaymentMethod from 'organisms/PaymentOptionCard/SelectAutoPaymentMethod';
import { neutrals } from 'utils/styles/color';
import { ReactComponent as SettingsIcon } from 'assets/icons/edit-settings.svg';

const PaymentPage = () => {
  const { width } = useWindowDimensions();

  const user = useBaoSelector((state) => state.user.value);
  const { currentCompany } = useBaoSelector((state) => state.common);

  const [state, setState] = useState('');
  const [primary, primaryChange] = useState<string>('');
  const [addingPaymentMethod, setAddingPaymentMethod] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [autoPayment, setAutoPayment] = useState<boolean>(false);
  const [paymentsLength, setPaymentsLength] = useState<number>(0);
  const [paymentMethods, setPaymentMethods] = useState<PMethod[]>([]);
  const [mainPaymentMethod, setMainPaymentMethod] = useState<PMethod | null>(
    null
  );
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);
  const [showSelectAutoPayment, setShowSelectAutoPayment] =
    useState<boolean>(false);
  const [selectAutoPayment, setSelectAutoPayment] = useState<PMethod | null>(
    null
  );
  const [isChangingPaymentMethod, setIsChangingPaymentMethod] =
    useState<boolean>(false);

  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const termsAndConditions = useCallback(
    () => (
      <>
        I confirm that I am authorized to initiate transfers from the designated
        accounts and I authorize such transactions from the designated accounts
        each month, via automatic electronic withdrawal or card payment. I will
        not dispute the scheduled transactions with my bank or card company;
        provided the transactions correspond to the terms indicated in this
        authorization form. <br /> <br />
        I understand that this authorization will remain in effect until I
        cancel it, and I agree to update my settings on the Zazmic-Connect for
        any changes in my account information or termination of this
        authorization at least 15 days prior to the next invoice due date. If
        the payment date falls on a weekend or holiday, I understand that the
        payment may be executed on the next business day. <br /> <br />
        In the case of an ACH transaction being rejected for Non-Sufficient
        Funds (NSF) I understand that Zazmic Inc. may at its sole discretion
        attempt to process the charge again within 30 days, and agree to an
        additional $50 charge for each attempt returned NSF which will be
        initiated as a separate transaction from the authorized recurring
        payment. I acknowledge that the origination of ACH transactions to my
        account must comply with the provisions of U.S. law. I also understand
        that the amount of the payments may vary each month and the monthly
        invoice from Zazmic, Inc will be the only notice of the AutoPay amount
        to be deducted. <br /> <br />
        In the case of payment via cards (E.g., credit card, debit card), I
        agree to pay for the card processing fees that Zazmic Inc. will add to
        the due charges. The card processing fees added by Zazmic are the fees
        due to the card processor and may include regular processing fees,
        conversion fees, international fees. <br /> <br />
        <b style={{ fontWeight: 500 }}>
          If you wish to cancel AutoPay you may do so in the Zazmic-Connect. If
          you have any questions regarding the Auto Payment program, please
          contact us at{' '}
          <Link
            rel="noopener noreferrer"
            target="_blank"
            to="mailto:finance@zazmic.com"
            $fontSize="0.875rem"
          >
            finance@zazmic.com
          </Link>
          .
        </b>
      </>
    ),
    []
  );

  const hideCaptcha = useCallback(() => {
    setDeleteDialog(false);
    setShowCaptcha(false);
  }, []);

  const showCaptchaModal = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (width < 600) {
      setDeleteDialog(false);
    }
    setShowCaptcha(true);
  }, []);

  const updateAuto = useCallback(async () => {
    if (!currentCompany) return;

    await axios
      .patch(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/update-payment-settings`,
        {
          is_auto_payment: !autoPayment,
          company_hs_id: currentCompany.company_hs_id,
        }
      )
      .then(({ data }) => {
        if (data) {
          setAutoPayment(!autoPayment);
        }
      });
    hideCaptcha();
  }, [hideCaptcha, autoPayment, currentCompany]);

  const handleSubmitAutoPayment = useCallback(
    (method: PMethod) => {
      setSelectAutoPayment(method);
      setShowSelectAutoPayment(false);
      setDeleteDialog(true);
    },
    [paymentMethods, isChangingPaymentMethod]
  );

  const handleChangeAutoPayment = useCallback(async () => {
    if (!currentCompany) return;
    setDeleteDialog(false);

    try {
      if (selectAutoPayment) {
        await axios.patch(
          `${process.env.REACT_APP_BILLING_SERVICE}/payment/update-main-payment-method`,
          {
            id: selectAutoPayment?.id,
            company_hs_id: currentCompany?.company_hs_id,
          }
        );
      }
    } finally {
      if (!isChangingPaymentMethod) await updateAuto();

      setSelectAutoPayment(null);
      setIsChangingPaymentMethod(false);
      fetchPaymentMethods({ currentCompany, customer: true }).then((res) => {
        const foundMainPaymentMethod =
          res.find((p: PMethod) => p.isMain) || null;

        setPaymentMethods(res);
        setMainPaymentMethod(foundMainPaymentMethod);
        setPaymentsLength(res.length);
      });
    }
  }, [selectAutoPayment, paymentMethods, autoPayment, isChangingPaymentMethod]);

  useEffect(() => {
    if (!currentCompany) return;

    axios
      .get(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/payment-settings`,
        {
          params: {
            company_hs_id: currentCompany.company_hs_id,
          },
        }
      )
      .then(({ data }) => {
        fetchPaymentMethods({ currentCompany, customer: true }).then((res) => {
          const foundMainPaymentMethod =
            res.find((p: PMethod) => p.isMain) || null;
          setPaymentMethods(res);
          setMainPaymentMethod(foundMainPaymentMethod);
          setPaymentsLength(res.length);
          if (res.length === 0) {
            setAutoPayment(false);
          } else {
            setAutoPayment(data.is_auto_payment);
          }
        });
      });
  }, [addingPaymentMethod, state, primary, currentCompany]);

  if (width < 600 && !isSuperAdmin) {
    if (deleteDialog) {
      return (
        <MobilePageWrapper
          title={
            <MobileInvoicesTitle variant="h2" my={3}>
              {autoPayment ? 'Payment Settings' : 'Auto payment'}
            </MobileInvoicesTitle>
          }
        >
          <MobilePaymentTermsContent>
            {!autoPayment ? (
              <>
                <Typography component="h5">Auto payment</Typography>
                <Typography>
                  Please confirm if you wish to deactivate the automatic
                  payment.
                </Typography>
              </>
            ) : (
              <>
                <Typography component="h5">Terms and Conditions</Typography>
                <Typography>{termsAndConditions()}</Typography>
              </>
            )}
            <Stack direction="row" spacing={1} pt={2}>
              <OutlinedBtn
                variant="outlined"
                onClick={() => setDeleteDialog(false)}
              >
                Cancel
              </OutlinedBtn>
              <ContainedBtn
                variant="contained"
                type="submit"
                onClick={showCaptchaModal}
              >
                {autoPayment ? 'Confirm' : 'Accept'}
              </ContainedBtn>
            </Stack>
          </MobilePaymentTermsContent>
        </MobilePageWrapper>
      );
    }

    return (
      <MobilePageWrapper
        title={
          <MobileInvoicesTitle variant="h2" my={3}>
            Payment settings
          </MobileInvoicesTitle>
        }
      >
        <MobilePaymentSettingsContent>
          <Box>
            <Typography>Auto payment</Typography>
            {paymentsLength !== 0 && paymentMethods.length > 0 && (
              <AntSwitch
                checked={autoPayment}
                onChange={(e) => {
                  e.preventDefault();
                  setDeleteDialog(true);
                }}
              />
            )}
          </Box>
          <Box>
            <PaymentOptionRadio
              onPrimaryChange={primaryChange}
              stateChanger={setState}
              dialogOpened={addingPaymentMethod}
              onDialogOpen={setAddingPaymentMethod}
            />
          </Box>
        </MobilePaymentSettingsContent>

        <AddPaymentMethodDialog
          addingPaymentMethod={addingPaymentMethod}
          onAddingPaymentMethod={setAddingPaymentMethod}
        />

        {showCaptcha ? (
          <CaptchaModal
            onComplete={updateAuto}
            onClose={hideCaptcha}
            className="mobile-modal"
          />
        ) : (
          <span />
        )}
      </MobilePageWrapper>
    );
  }

  const isDisablingAutoPayment = autoPayment && !isChangingPaymentMethod;

  return (
    <PageWrapper title="Payment settings">
      <Container>
        <Card>
          {/* <Box sx={{ padding: '12px 16px 16px 16px' }}> */}
          {/*   <PaymentOptionRadio */}
          {/*     onPrimaryChange={primaryChange} */}
          {/*     stateChanger={setState} */}
          {/*     dialogOpened={addingPaymentMethod} */}
          {/*     onDialogOpen={setAddingPaymentMethod} */}
          {/*   /> */}
          {/* </Box> */}
          <PaymentOptionCard
            mainPaymentMethod={mainPaymentMethod}
            stateChanger={setState}
            onDialogOpen={setAddingPaymentMethod}
          />
        </Card>

        <Card sx={{ minHeight: '240px', width: 400 }}>
          <Box display="flex" alignItems="center" height={80} p="0 12px">
            <CardHeader
              title="Auto Payment"
              avatar={<CardAvatar />}
              sx={{ p: 0 }}
            />
          </Box>
          <Box p="0 12px" mb="30px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              height="56px"
            >
              <Stack direction="row" gap="16px" alignItems="end">
                <StyledAutoPaymentText>Auto payment is</StyledAutoPaymentText>
                <StyledAutoPaymentLabel
                  color={
                    autoPayment ? palette.success[600] : palette.warning[500]
                  }
                >
                  {autoPayment ? 'ENABLED' : 'TURNED OFF'}{' '}
                </StyledAutoPaymentLabel>
              </Stack>
              {paymentsLength !== 0 && paymentMethods.length > 0 && (
                <AntSwitch
                  checked={autoPayment}
                  onChange={(e) => {
                    e.preventDefault();
                    if (autoPayment) {
                      setDeleteDialog(true);
                    } else {
                      showCaptchaModal();
                    }
                  }}
                />
              )}
            </Box>
            <Box
              display="flex"
              marginBottom="40px"
              height="56px"
              alignItems="center"
            >
              {!autoPayment && paymentsLength === 0 && (
                <Typography
                  color={neutrals[500]}
                  fontSize="12px"
                  fontWeight={300}
                  lineHeight="18px"
                >
                  Add payment method to be able to enable auto payment
                </Typography>
              )}
              {!autoPayment && paymentsLength !== 0 && (
                <Typography
                  color={neutrals[500]}
                  fontSize="12px"
                  fontWeight={300}
                  lineHeight="18px"
                  fontFamily="Poppins, sans-serif"
                >
                  Enable auto payment to ensure that invoices are paid on their
                  due date using the specified payment method. Please note that
                  overdue invoices will not be paid automatically after auto
                  payment is enabled.
                </Typography>
              )}
              {autoPayment && mainPaymentMethod && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <PaymentMethod method={mainPaymentMethod} />
                  <SettingsIcon
                    onClick={() => {
                      setShowSelectAutoPayment(true);
                      setIsChangingPaymentMethod(true);
                    }}
                    style={{
                      color: '#6F9FE9',
                      cursor: 'pointer',
                    }}
                  />
                </Stack>
              )}
            </Box>
          </Box>
        </Card>
      </Container>

      <AddPaymentMethodDialog
        addingPaymentMethod={addingPaymentMethod}
        onAddingPaymentMethod={setAddingPaymentMethod}
      />

      <Modal
        open={deleteDialog}
        maxWidth={isDisablingAutoPayment ? 'sm' : 'lg'}
        title={
          isDisablingAutoPayment
            ? 'Turn off auto payment?'
            : 'Auto payment terms and conditions'
        }
        subtitle={
          isDisablingAutoPayment
            ? `Once auto payment is disabled, your invoices will no longer be processed automatically on their due date using the selected payment method. However, you will still be able to pay them manually.`
            : 'To enable auto payment please read and accept payment terms and conditions'
        }
        onClose={() => setDeleteDialog(false)}
      >
        <>
          {!isDisablingAutoPayment && (
            <ModalContent>
              <ModalContentText>{termsAndConditions()}</ModalContentText>
            </ModalContent>
          )}
          <ModalActions>
            <ModalCloseButton
              autoFocus
              size="medium"
              variant="outlined"
              onClick={() => setDeleteDialog(false)}
            >
              {isDisablingAutoPayment ? 'Cancel' : 'Decline'}
            </ModalCloseButton>
            <ModalSubmitButton
              size="medium"
              variant="contained"
              onClick={() =>
                width < 600 ? showCaptchaModal() : handleChangeAutoPayment()
              }
            >
              {isDisablingAutoPayment ? 'Confirm' : 'Accept'}
            </ModalSubmitButton>
          </ModalActions>
        </>
      </Modal>

      {showCaptcha ? (
        <CaptchaModal
          onComplete={() => {
            setShowCaptcha(false);
            setShowSelectAutoPayment(true);
          }}
          onClose={hideCaptcha}
        />
      ) : (
        <span />
      )}

      {showSelectAutoPayment ? (
        <SelectAutoPaymentMethod
          onClose={() => setShowSelectAutoPayment(false)}
          onSubmit={handleSubmitAutoPayment}
          paymentMethods={paymentMethods}
          defaultMethod={
            autoPayment && mainPaymentMethod ? mainPaymentMethod : null
          }
        />
      ) : (
        <span />
      )}
    </PageWrapper>
  );
};

export default PaymentPage;
