export const ADMIN = 'admin';
export const SUPER_ADMIN = 'superadmin';

export const SALES_REP = 'sales_rep';
export const roles = [ADMIN, SUPER_ADMIN, SALES_REP] as const;

export const isAnyAdmin = (role?: string) => {
  if (!role) return false;
  return role === ADMIN || role === SUPER_ADMIN || role === SALES_REP;
};

export function getRoleLabel(role: (typeof roles)[keyof typeof roles]) {
  switch (role) {
    case ADMIN:
      return 'Admin';
    case SUPER_ADMIN:
      return 'Super Admin';
    case SALES_REP:
      return 'Sales Rep';
    default:
      return '';
  }
}
