import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { SalesReportRow } from 'organisms/SalesReportTable';

const initialState: { count: number; deals: SalesReportRow[] } = {
  count: 0,
  deals: [],
};

export const getDeals = createAsyncThunk(
  'deals/getReports',
  async ({
    company_id,
    limit,
    page,
  }: {
    company_id: string;
    limit?: number;
    page?: number;
  }) => {
    try {
      const { data } = await axios.get<{
        count: number;
        deals: SalesReportRow[];
      }>(`${process.env.REACT_APP_REPORT_SERVICE}/deal/company`, {
        params: {
          company_id,
          ...(limit ? { per_page: limit } : {}),
          ...(page ? { page: page + 1 } : {}),
        },
      });

      return data;
    } catch (error) {
      return {};
    }
  }
);

export const dealSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<{ count: number; deals: SalesReportRow[] }>
    ) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(getDeals.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  },
});

export const { setData } = dealSlice.actions;

export default dealSlice.reducer;
